import { Component, useState } from 'react';
import { FiSearch } from 'react-icons/fi';

import './style/style.css';

import Cepfinder from './assets/cepfinder';
import Adressfinder from './assets/adressfinder';

import React from 'react';

export default function Home() {

  const [componenteAtual, setComponenteAtual] = useState(<Cepfinder />);
  const handleButtonClick = (componente) => {
    setComponenteAtual(componente);
  }

  return (

    <div className='mainDiv'>
      <header className='header'>
        <button className='btnchange' onClick={() => handleButtonClick(<Cepfinder />)}>Buscar Endereço através do CEP</button>
        <button className='btnchange' onClick={() => handleButtonClick(<Adressfinder />)}>Buscar CEP através do Endereço</button>
      </header>
      {componenteAtual}

    </div>
  );
}