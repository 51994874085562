import React from 'react';

const AdressList = ({ enderecos }) => {
  return (
    <div>
      {enderecos.map((endereco, index) => (
        <div key={index}>
          <h2>Endereço {index + 1}</h2>
          <p>CEP: {endereco.cep}</p>
          <p>Logradouro: {endereco.logradouro}</p>
          <p>bairro: {endereco.bairro}</p>
          <p>complemento: {endereco.complemento}</p>
        </div>
      ))}
    </div>
  );
};

export default AdressList;